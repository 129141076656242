@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda+SC:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover cover;
  background-repeat: no-repeat;
  background-position: center center;
  background: linear-gradient(#d2deed, #779fcb); 
  
}

/* Home Page */
.display-home{
  font-family: "Bodoni Moda SC", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 9.4rem;
  margin-top: 20px;
  justify-content: center;
}
.lema{
  font-family: 'Times New Roman', Times, serif;
  color: #1f4aa3;
  font-weight: 100;
  font-size: 2rem; 
}
.word {
  display: inline-block;
}
.img-custom {
  max-width: 50%;
  height: auto;
  margin-bottom: 1.5rem; 
  padding-left: 0.5rem; 
  padding-right: 3rem;
}
.letter-home {
  font-size: 6rem;
}

.small-text-home {
  font-size: 2.5rem;
  margin-top: -15px; 
  font-weight: 100;
}



.display-title{
  font-family: "Fantasy", Copperplate;
}

.paragraph{
  font-family: "Courier New", monospace;
  font-weight: 500;
}


/* ARASA Cascada Inicio */
.fancy-text {
  font-family: 'Times New Roman', Times, serif;
  color: #1f4aa3;
  padding: 0px;

}

.ARASADispCont{
  font-size: 6rem;
  text-align: center;
}

.letter {
  font-size: 6rem;
  margin: 20px 0;
}

.small-text {
  font-size: 2.5rem;
  margin-top: -15px; 
  font-weight: 100;
}
/* ARASA Cascada Fin */


.icon-large {
  width: 110px; 
  height: auto; 
}

.list-text {
  font-size: 1.5rem;
  font-family: "Courier New", monospace;
  font-weight: 500;
}

.list-text-i {
  font-family: "Lucida Console", monospace;
  font-weight: 500;
}



/* Experience Page */
.image-gallery-item {
  position: relative;

}
.image-gallery-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  max-width: 80%; 
}






/** slider Diego **/
:root { --n-logos: 6; }
@keyframes sliderAnimation {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-600% / var(--n-logos))); }
}
.slider { overflow-x: hidden; position: relative; z-index: 4; }
.slider .overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 3; background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 5%, rgba(255,255,255,0) 95%, rgba(255,255,255,1) 100%); }
.sp .slider .overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 3; background: linear-gradient(90deg, rgba(244,250,255,1) 0%, rgba(244,250,255,0) 5%, rgba(244,250,255,0) 95%, rgba(244,250,255,1) 100%); }
.slider .track { display: flex; animation: sliderAnimation 30s linear infinite; position: relative; z-index: 2; }
/* .slider:hover .track { animation-play-state: paused; } */
.slider .track .item { position: relative; flex: 0 0 calc(100% / var(--n-logos)); padding: 0 1.5rem; }
.slider .track .item img { width: 100%; height: auto; display: block; text-align: center; margin: 0 auto; }












/* ⁠ xxl ⁠ applies to x-large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {

}

/* ⁠ xl ⁠ applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .ARASADispCont{
    font-size: 5rem;
  }
}

/* ⁠ lg ⁠ applies to medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .letter {
    font-size: 4rem;
  }
  .small-text {
    font-size: 2rem;
  }
  .ARASADispCont{
    font-size: 3rem;
  }
}


/* ⁠ md ⁠ applies to small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .display-home{
    font-size: 7.2rem;
  }
  .letter {
    font-size: 2rem;
  }
  .small-text {
    font-size: 1.5rem;
  }
}

/* ⁠ sm ⁠ applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .display-home{
    font-size: 4.7rem;
  }
  .fancy-text {
    padding: 20px;
  }
  .letter-home {
    font-size: 3rem;
  }
  .small-text-home {
    font-size: 1.5rem;
  }
  .lema{
    font-size: 1rem; 
  }
}